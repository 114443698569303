
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}
.serial-no {
  text-align: center;
}
.ant-modal .ant-modal-content{
  padding: 0!important;
}

::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background-color: normal;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background-color: #882222;
  border-radius: 10px;
}

